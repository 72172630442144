.contacts {
    margin-bottom: 3.6rem;
}
.inner {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    /*grid-template-rows: 1fr 1fr;*/
    gap: 5rem;
    align-self: center;
    justify-items: center;
    /*margin-top: rem;*/
    /*margin-bottom: 3rem;*/
    padding: 2rem 15rem;
}
.social a{
    display: flex;
    justify-content: center;
    width: 7rem;
    padding: 1.5rem;
    transition: all 0.3s ease;
}
.social a:hover{
rotate: 10deg;
    scale: 1.1;
 }
.link img:hover{

    transition: all 0.3s ease;
}
.link img{
    width: 6rem;
    text-align: center;
}

.tel{
    display: flex;
    justify-content: center;
    align-items: center;

}
.telLink{
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-size: 4rem;
    font-weight: 700;
    width: 4rem;
    height: 4rem;
    color: #000;
    transition: all .5s ease ;
    /*background: #a0060c;*/
    /*border-radius: 50%;*/
    margin: 4rem;
}
.telLink:hover{
    color: #a0060c;
}

.telImg{
    width: 6rem;
    transition: all .5s ease ;
}
.telImg:hover{
    rotate: 15deg;
    scale: 1.1;
}

.place{
    display: flex;
    justify-content: center;
    align-items: center;
    /*flex-direction: column;*/
    margin-bottom: 3rem;
    margin-top: 10rem;
}
.linkPlace{
    display: flex;
    align-items: center;
    font-size: 4rem;
    font-weight: 700;
    margin-right: 3rem;
    margin-left: 3rem;
    text-decoration: none;
    color: #000;
transition: all .3s ease;
}
.linkPlace:hover{
    color: #a0060c;
}
.linkPlace img{
    width: 5rem;
    margin-right: 1rem;
}

@media screen and (max-width: 460px){
    html{
        font-size: 8px;
    }
.inner{
    padding: 0;
}
.tel{
    margin-top: 60px;
}
}