.info{
    margin-bottom: 10rem;
}
.inner{

}
.topSection{
    display: flex;
    justify-content: center;
    align-items: flex-end;

    margin-bottom: 4.4rem;
}
.title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100vw;
    align-items: center;
    background: url('../../img/mid.svg');
    background-position: right;
    background-size: contain;
    background-repeat: no-repeat;
    text-align: center;
    /*padding-right: 5.5rem;*/
}
.title h1{
    font-weight: 700;
    font-size: 4.9rem;
    /*color: #a0060c;*/
    color: #000000;
}
.title span {
    font-weight: 700;
    font-size: 4.9rem;
}
.bottomSection{
    display: flex;
    justify-content: center;
    align-items: center;

}
.img{
    max-width: 100%;
}
.subtitle{
    font-weight: 700;
    font-size: 3.6rem;
    color: #a0060c;
    text-transform: uppercase;
}

@media screen and (max-width: 460px){
    .title{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 2rem;
}
.subtitle{
    font-size: 2.5rem;
}
}

